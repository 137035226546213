import './App.scss';
import Api from './service/api';
import Favicon from 'react-favicon';
import auth from './app-auth/authPage';
import PrivateRoute from './PrivateRoutes';
import { useEffect, useState } from 'react';
import DomainContext from './app-utils/DomainContext';
import React, { createContext, Suspense } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Translator, LanguageList, Config } from "react-translator-component";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd';
import { SessionPage } from './Pages/SessionExpired/SessionPage';

const api = new Api();
const NavigationComp = React.lazy(() => import('./Pages/Navigation'));

export const LangTranslator = createContext(null);

function App() {

  const [lang, setLang] = useState();
  const [domainDetails, setDomainDetails] = useState();

  useEffect(() => {
    getDomainInfo();
    setLanguageSpecific();
    // eslint-disable-next-line
  }, [])

  const setLanguageSpecific = () => {
    var fullLang = window.location.hash.split('lang=')[1];
    if (fullLang) {
      sessionStorage.setItem('lang', fullLang);
    } else {
      fullLang = sessionStorage.getItem('lang');
    }
    const newLang = sessionStorage.getItem('changedlang');
    if (newLang) {
      setLang(sessionStorage.getItem('changedlang'));
      Config.default = sessionStorage.getItem('changedlang');
      sessionStorage.removeItem('changedlang');
      return;
    }
    var search = fullLang?.split('-')[0]?.toLowerCase();
    var searchSuffix = fullLang?.split('-')[1]?.toLowerCase();
    if (search === 'en') {
      setLang('en');
      Config.default = 'en';
    } else if ((search === 'zh' && (searchSuffix === 'cn' || searchSuffix === 'hk')) || search === 'cn') {
      setLang('znch');
      Config.default = 'znch';
    } else if ((search === 'zh' && searchSuffix === 'tw')) {
      setLang('zhcht');
      Config.default = 'zhcht';
    } else if (search === 'ms') {
      setLang('ms');
      Config.default = 'ms';
    } else if (search === 'ko') {
      setLang('ko');
      Config.default = 'ko';
    } else if (search === 'th') {
      setLang('th');
      Config.default = 'th';
    } else {
      setLang('en');
      Config.default = 'en';
    }
  }

  Config.list = {
    en: {
      text: "English",
      file: require("./i18n/en")
    },
    znch: {
      text: "chinese(Simplified)",
      file: require("./i18n/zn-ch")
    },
    zhcht: {
      text: "chinese(Traditional)",
      file: require("./i18n/zh-CHT")
    },
    ms: {
      text: "Malay",
      file: require("./i18n/ms")
    },
    ko: {
      text: "Korean",
      file: require("./i18n/ko")
    },
    th: {
      text: "Thai",
      file: require("./i18n/th")
    },
  };

  const getDomainInfo = async () => {
    const url = 'auth/domain';
    let result = await api.mainRestCallService(url, 'GET');
    if (result?.data) {
      var data = JSON.parse(JSON.stringify(result.data));
      data = JSON.parse(data);
      setDomainDetails(data);
      document.title = data.title.concat(' | ').concat(data.descriptionClient)
      const linkElement = document.createElement("link");
      linkElement.setAttribute("rel", "stylesheet");
      linkElement.setAttribute("type", "text/css");
      linkElement.setAttribute("href", data.cdnDomain + data.css);
      document.head.appendChild(linkElement);
    }
  }

  const langChange = (lang) => {
    sessionStorage.setItem('changedlang', lang);
    window.location.reload();
  }

  return (
    <div id="domainClass" className="default">
      {
        domainDetails ?
          <DomainContext.Provider value={domainDetails}>
            <Favicon url={domainDetails?.cdnDomain + domainDetails?.favIcon} />
            <Translator>
              <LangTranslator.Provider value={{ lang, langChange }}>
                <HashRouter>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                      <Route path="/login" exact component={auth} />
                      <Route path="/login/:token" exact component={auth} />
                      <Route path="/verify-otp" exact component={auth} />
                      <Route path="/forgot-password" exact component={auth} />
                      <Route path="/setpassword/:uuid/:reset_token" exact component={auth} />
                      <Route path="/auth/reset-password/:uuid/:token" exact component={auth} />
                      <Route path="/auth/access/:guid" component={auth} />
                      <Route path="/ssologin/:access_token" component={auth} />
                      <Route path="/success" component={LoadFundSuccess} />
                      <Route path="/failure" exact component={LoadFundFailure} />
                      <Route path="/kyc-verification" component={KYCVerification} />
                      <Route path="/session-expired" component={SessionPage} />
                      <PrivateRoute path="/" component={NavigationComp} />
                      <LanguageList Language={lang} />
                    </Switch>
                  </Suspense>
                </HashRouter>
              </LangTranslator.Provider>
            </Translator>
          </DomainContext.Provider>
          : null
      }
    </div>
  );
}

export default App;

// load fund success Div for JKO pay and rapyd and world payment
export const LoadFundSuccess = () => {

  useEffect(() => {
    window.parent.postMessage('load_funds_success', '*');
  }, []);

  const closeWindow = () => {
    window.close();
  }

  return (
    <>
      <Modal visible={true} closable={false} centered style={{ maxWidth: '424px' }} footer={null}>
        <div style={{ display: 'flex', flexFlow: 'column wrap', alignItems: 'center' }}>
          <CheckCircleOutlined style={{ color: 'var(--positive)', fontSize: '60px', paddingBottom: '20px' }} />
          <span style={{ fontSize: 24 }}>{'Payment Successful!'}</span>
          <span style={{ fontSize: 14, paddingTop: 8 }}>{'Hooray! You have completed your payment.'}</span>
          <div style={{ textAlign: 'center', marginTop: 24, width: "100%" }}>
            <Button key="close" className="btn" style={{ background: 'green', width: "100%", height: 48 }} onClick={closeWindow}> {'Close'} </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const LoadFundFailure = () => {

  useEffect(() => {
    window.parent.postMessage('load_funds_failure', '*');
  }, []);

  const closeWindow = () => {
    window.close();
  }

  return (
    <>
      <Modal visible={true} closable={false} centered style={{ maxWidth: '424px' }} footer={null}>
        <div style={{ display: 'flex', flexFlow: 'column wrap', alignItems: 'center' }}>
          <CloseCircleOutlined style={{ color: 'var(--negative)', fontSize: '60px', paddingBottom: '20px' }} />
          <span style={{ fontSize: 24 }}>{'Payment Failed!'}</span>
          <span style={{ fontSize: 14, paddingTop: 8 }}>{"We aren't able to process your payment. Please try again."}</span>
          <div style={{ textAlign: 'center', marginTop: 24, width: "100%" }}>
            <Button key="close" className="btn" style={{ background: 'red', width: "100%", height: 48 }} onClick={closeWindow}> {'Close'} </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export const KYCVerification = () => {
  useEffect(() => {
    window.parent.postMessage('kyc_status_check', '*');
  }, []);
  return (<></>)
}